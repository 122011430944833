@import './reset';
@import './themes';

@include font-face();

#app {
  width: $app-width;
  height: $app-height;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: contain;
  &.first-theme {
    background-image: url('./../images/backgrounds/#{$first-bg}');
  }
  &.second-theme {
    background-image: url('./../images/backgrounds/#{$second-bg}');
  }
  &.third-theme {
    background-image: url('./../images/backgrounds/#{$third-bg}');
  }
  &.fourth-theme {
    background-image: url('./../images/backgrounds/#{$fourth-bg}');
  }
}

.view {
  width: $app-width;
  height: $app-height;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  color: #ffffff;
  font-size: toRem(40);
}
