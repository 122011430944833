/* Settings */
//app size

$app-width: 1280px;
$app-height: 720px;

//DON'T CHANGE: sizes used to develop the app
$original-width: 1920px;
$original-height: 1080px;

@function toRem($target) {
  @return ($target / 16) * 1rem;
}

@function toPx($target) {
  @return $target + px;
}

@function v($q) {
  /*This function rescale the element vertically depending on the settings*/
  @return floor($q * $app-height / $original-height);
}

@function h($q) {
  /*This function rescale the element horizontally depending on the settings*/
  @return floor($q * $app-width / $original-width);
}
