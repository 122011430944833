@import 'mixins';
/*COLORS -------------------------------------------------------------------------------------------------------------*/
$color-main: #ffffff;
//focus:
$color-focus: #9dbdda;
//shadows:
$color-shadow: #000;
//background bottom menu:
$color-bg: rgba(255, 255, 255, 0.35);
$secondary-bg: #8a9bab;
//switches:
$switch-active: #3ae1ab;
$switch-inactive: #3f7785;
//texts:
$primary-text: #ffffff;
$secondary-text: #e6e6e6;
$third-text: #4a4a4a;

/*BACKGROUNDS --------------------------------------------------------------------------------------------------------*/
$first-bg: 'background.png';
$second-bg: 'background-2.jpg';
$third-bg: 'background-3.jpg';
$fourth-bg: 'background-4.jpg';

/*FONTS --------------------------------------------------------------------------------------------------------------*/
$font-family: 'abel';

@mixin font-face() {
  @font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: 300;
    src: url('./../fonts/abel.ttf') format('truetype');
  }
  @font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    src: url('./../fonts/abel.ttf') format('truetype');
  }
  @font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    src: url('./../fonts/abel.ttf') format('truetype');
  }

  @font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    src: url('./../fonts/abel.ttf') format('truetype');
  }
  @font-face {
    font-family: nasalization;
    font-style: normal;
    src: url('./../fonts/nasalization.ttf') format('truetype');
  }
  @font-face {
    font-family: monserrat;
    font-style: normal;
    src: url('./../fonts/Montserrat-Regular.ttf') format('truetype');
  }
}
%font-light {
  font-family: $font-family;
  font-weight: 300;
}
%font-regular {
  font-family: $font-family;
  font-weight: 400;
}
%font-semibold {
  font-family: $font-family;
  font-weight: 600;
}
%font-bold {
  font-family: $font-family;
  font-weight: 700;
}
%font-nasalization {
  font-family: 'nasalization';
  font-weight: 700;
}
%font-monserrat {
  font-family: 'monserrat';
  font-weight: 400;
}

/*ANIMATIONS ---------------------------------------------------------------------------------------------------------*/
%shine {
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 60%
  );
  background-size: 500% 500%;
  @include animation(shine 3s linear infinite);
  @include keyframes(shine) {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
}
