@import './../../assets/styles/sizes';
@import './../../assets/styles/themes';

.viewLoading {
  position: absolute;
  top: v(-100px);
}
.loading {
  position: absolute;
  width: h(1920px);
  height: v(1080px);
  top: 0;
  left: 0;
  z-index: 100;
  .loadingSpinner {
    background-position: center;
    background-image: url(../../assets/images/loading_spinner.gif);
    background-repeat: no-repeat;
    top: ($app-height - v(120px))/2;
    left: ($app-width - v(120px))/2;
    width: v(120px);
    height: v(120px);
    background-size: cover;
    position: absolute;
  }
}
